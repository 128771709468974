import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import SpeakerPhoneIcon from "@mui/icons-material/SpeakerPhone";
import {
  Box,
  Collapse,
  Divider,
  ListItemButton,
  ListItemIcon,
  Typography,
  useTheme,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { setFeatureLoading } from "../../../features/dashboard/appFeature.slice";
import { isEmpty } from "../../../utils/Util";
import { DealerProfile, TargetPayApi } from "../../../utils/UtilTarget";
import TargetPay from "./TargetPay.png";
import PerseusPay from "./PerseusPay.png";

const drawerWidth = 240;

interface Props {
  window?: () => Window;
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
}
// let PaymentGatewayId = -1;

interface NavItem {
  title: string;
  path?: string;
  icon: React.ReactNode;
  PaymentGateway: number;
  children?: NavItem[];
}

const Nav = (props: Props) => {
  let dealerId = useAppSelector((state: RootState) => state.dealer.dealerId);
  // const paymentGatewayId = GetPaymentGateway();
  const [paymentGatewayId, SetPaymentGatewayId] = React.useState<any>(-1);
  const [tlDomainUser, SetTlDomainUser] = React.useState<any>(false);
  const [reportsOpen, setReportsOpen] = React.useState(false);
  const [selectedPath, setSelectedPath] = React.useState<string | null>(null);
  const [ReportFeatureFlag, SetReportFeatureFlag] = React.useState<any>(false);
  const [imageError, setImageError] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const handleImageError = () => {
    setImageError(true);
  };

  // Update dealerId in the url based on dealerId selection fom select component
  useEffect(() => {
    setSelectedPath(location.pathname);
    if (location.pathname.toLocaleLowerCase().includes("report")) {
      setReportsOpen(true); // Open the sub-menu if sub item is clicked
    } else {
      setReportsOpen(false); // Close the sub-menu if a non-parent item is clicked
    }

    if (location.pathname.includes("/dashboard/settings/")) {
      navigate(`/dashboard/settings/${dealerId}`);
    }
  }, [dealerId, location.pathname, navigate]);

  useEffect(() => {
    GetPaymentGateway();
  }, [dealerId]);

  useEffect(() => {
    const awaitFeatureList = async () => {
      try {
        await GetFeaturesList().then((res) => {
          if (res) {
            res.forEach((element) => {
              if (element.featureName === "Reports") {
                SetReportFeatureFlag(element.isEnabled);
                localStorage.setItem("report_feature_flag", element.isEnabled);
              }
            });
            dispatch(setFeatureLoading(true));
          }
        });
      } catch (error) {
        console.error("Error fetching Reports:", error);
      }
    };
    awaitFeatureList();
  }, []);

  async function GetPaymentGateway() {
    const isTLDomainUser = localStorage.getItem("isTLDomainUser");
    SetTlDomainUser(isTLDomainUser);
    if (isTLDomainUser === "true") return;
    if (isEmpty(dealerId)) dealerId = await DealerProfile("dealerId");
    if (isEmpty(dealerId)) return;

    // Get Dealer Payment Gateway
    let payment_gateway = await TargetPayApi(
      `GetDealerPaymentGateway?dealerId=${dealerId}`,
      null,
      "get"
    );
    SetPaymentGatewayId(Number(payment_gateway));
    localStorage.setItem("payment_gateway", payment_gateway);
  }

  async function GetFeaturesList() {
    // Get Features List
    dispatch(setFeatureLoading(false));
    localStorage.setItem("report_feature_flag", ReportFeatureFlag);
    return await TargetPayApi(``, null, "get", "AppFeature");
  }

  const { window, handleDrawerToggle, mobileOpen } = props;

  const navConfig: NavItem[] = [
    {
      title: "Dashboard",
      path: "/dashboard/app",
      icon: <DashboardIcon />,
      PaymentGateway: 0,
    },
    {
      title: "Transactions",
      path: "/dashboard/transactions",
      icon: <AccountBalanceIcon />,
      PaymentGateway: 0,
    },
    // {
    //   title: "Vaulted Card",
    //   path: "/dashboard/vaulted-card",
    //   icon: <CreditCardIcon />,
    // },
    // {
    //   title: "Settings",
    //   path: `/dashboard/settings/${dealerId}`,
    //   icon: <SettingsIcon />,
    //   PaymentGateway: 0
    // },
  ];

  if (paymentGatewayId == -1) {
    navConfig.length = 0;
  }
  // Check if localStorage key "Login_With" is set to "TargetPay"
  const loginWithTargetPay = localStorage.getItem("Login_With") === "TargetPay";

  // Add "User" page to navConfig only if not logged in with "TargetPay"
  // if (!loginWithTargetPay) {
  //   navConfig.push({
  //     title: "User",
  //     path: "/dashboard/users",
  //     icon: <PeopleAltIcon />,
  //     PaymentGateway: 0,
  //   });
  // }

  if (paymentGatewayId == 1) {
    navConfig.push({
      title: "Settings",
      path: `/dashboard/settings/${dealerId}`,
      icon: <SettingsIcon />,
      PaymentGateway: 1,
    });
  }

  if (paymentGatewayId == 2) {
    navConfig.push({
      title: "Terms & conditions",
      path: "/dashboard/perseuspaysetting",
      icon: <AppRegistrationIcon />,
      PaymentGateway: 2,
    });

    navConfig.push({
      title: "Terminal Settings",
      path: "/dashboard/terminalsetting",
      icon: <SpeakerPhoneIcon />,
      PaymentGateway: 2,
    });

    if (ReportFeatureFlag) {
      navConfig.push({
        title: "Reports",
        icon: <ListAltIcon />,
        PaymentGateway: 2,
        children: [
          {
            title: "Reconciliation",
            path: "/dashboard/dailyReconciliationReport",
            icon: <FactCheckIcon />,
            PaymentGateway: 2,
          },
        ],
      });
    }
  }

  if (tlDomainUser === "true") {
    navConfig.push({
      title: "Setup Customer",
      path: "/dashboard/preonboarding",
      icon: <SettingsIcon />,
      PaymentGateway: 2,
    });

    navConfig.push({
      title: "Onboarding Status",
      path: "/dashboard/onboardingStatus",
      icon: <DashboardIcon />,
      PaymentGateway: 2,
    });
  }

  if (paymentGatewayId == 0) {
    // const navConfig = [{
    //   title: "Onboarding",
    //   path: "/dashboard/perseuspaysetting",
    //   icon: <SettingsIcon />,
    //   PaymentGateway: 2
    // }];
    navConfig.length = 0;
    navConfig.push({
      title: "Onboarding",
      path: "/dashboard/perseuspaysetting",
      icon: <SettingsIcon />,
      PaymentGateway: 2,
    });
  }

  const handleReportsClick = () => {
    setReportsOpen(!reportsOpen);
  };

  const handleItemClick = (
    path: string | undefined,
    hasChildren: boolean = false
  ) => {
    if (path) {
      setSelectedPath(path);
    }
    if (!hasChildren) {
      setReportsOpen(false); // Close the sub-menu if a non-parent item is clicked
    }
  };

  const renderDrawerContent = (
    <div>
      <Box
        display="flex"
        justifyContent="left"
        alignItems="left"
        sx={{ padding: "15px 24px 15px 24px" }}
      >
        <Typography variant="h6">
          {paymentGatewayId == 1 ? "Payments" : ""}
        </Typography>
        {(paymentGatewayId == 0 ||
          paymentGatewayId == 2 ||
          paymentGatewayId == -1) && (
          <img
            src={imageError ? PerseusPay : theme.headerLogo}
            onError={handleImageError}
            style={{ height: "30px" }}
          />
        )}
      </Box>

      <Divider />

      <Box>
        <List disablePadding sx={{ p: 1 }}>
          {navConfig.map((item) => (
            <React.Fragment key={item.title}>
              <ListItem disablePadding>
                <ListItemButton
                  component={RouterLink}
                  to={item.path}
                  disableGutters
                  selected={selectedPath === item.path}
                  onClick={() => {
                    handleItemClick(item.path, !!item.children);
                    if (item.children) {
                      handleReportsClick();
                    }
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText disableTypography primary={item.title} />
                  {item.children ? (
                    reportsOpen ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItemButton>
              </ListItem>
              {item.children && (
                <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((subItem) => (
                      <ListItem key={subItem.title} disablePadding>
                        <ListItemButton
                          component={RouterLink}
                          to={subItem.path}
                          disableGutters
                          selected={selectedPath === subItem.path}
                          sx={{
                            pl: 4,
                          }}
                          onClick={() => handleItemClick(subItem.path, true)}
                        >
                          <ListItemIcon>{subItem.icon}</ListItemIcon>
                          <ListItemText
                            disableTypography
                            primary={subItem.title}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* Mobile View Sidebar */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {renderDrawerContent}
      </Drawer>

      {/* Desktop View Sidebar */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {renderDrawerContent}
      </Drawer>
    </Box>
  );
};

export default Nav;
